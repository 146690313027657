import type { IconComponent } from '@change-corgi/design-system/icons';
import {
	iconChatBubble,
	iconEmail,
	iconFacebook,
	iconFacebookMessenger,
	iconGoogle,
	iconInstagram,
	iconIosShare,
	iconLine,
	iconLink,
	iconNextdoor,
	iconReddit,
	iconTwitter,
	iconVk,
	iconWhatsapp,
} from '@change-corgi/design-system/icons';
import type { ColorName } from '@change-corgi/design-system/theme';

import type { Channel } from '../../shared/types';

import type { FillableButtonChannel } from './types';

const COLORS: Record<Channel, ColorName> = {
	facebook: 'social-facebook',
	gmail: 'social-gmail',
	google: 'social-google',
	whatsapp: 'social-whatsapp',
	twitter: 'social-twitter',
	facebookMessenger: 'social-facebookMessenger',
	line: 'social-line',
	reddit: 'social-reddit',
	vk: 'social-vk',
	instagram: 'social-instagram',
	nextdoor: 'social-nextdoor',
	email: 'social-email',
	sms: 'social-sms',
	copy: 'social-copy',
	mobileNativeShare: 'social-iosShare',
};

export function getSocialColor(social: Channel): ColorName {
	return COLORS[social];
}

const ICONS: Record<Channel, IconComponent> = {
	facebook: iconFacebook,
	gmail: iconGoogle,
	google: iconGoogle,
	whatsapp: iconWhatsapp,
	twitter: iconTwitter,
	facebookMessenger: iconFacebookMessenger,
	line: iconLine,
	reddit: iconReddit,
	vk: iconVk,
	instagram: iconInstagram,
	nextdoor: iconNextdoor,
	email: iconEmail,
	sms: iconChatBubble,
	copy: iconLink,
	mobileNativeShare: iconIosShare,
};

export function getSocialIcon(social: Channel): IconComponent {
	return ICONS[social];
}

const FILLABLE_BUTTON_CHANNELS: Record<FillableButtonChannel, true> = {
	facebook: true,
	gmail: true,
};

export function isButtonFillable(social: Channel): boolean {
	return !!(FILLABLE_BUTTON_CHANNELS as Partial<Record<Channel, true>>)[social];
}
